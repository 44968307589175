html {
    scroll-behavior: smooth;

}

* {
    color: #0d0d0d;
    
}


body {
    background-color: #f7f7f7;
    height: 100%;
    margin: 0;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: #edf2f4;
}

::-webkit-scrollbar-thumb {
    background-color: #151c29;
    border-radius: 10px;
}
