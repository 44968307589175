.my-projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1.25rem 1.25rem 1.25rem;
    background-color: #e9e9e9;
    border-radius: 8px;
}

.project-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 70%;
    gap: 45px;
    margin-bottom: 6rem;
}

.project {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 40%;
    height: 30rem;
    border: 1px solid #151c29;
    border-radius: 8px;
    background-color: #f7f7f7;


}

.project-desc {
    display: flex;
    padding: 1rem;
    height: 5rem;
    color: #0d0d0d;
}

.project-1,
.project-2,
.project-3 {
    width: 100%
}

.awn-pic {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('https://res.cloudinary.com/dgxpqnbwn/image/upload/v1695663088/Screenshot_2023-09-25_at_10.30.51_AM_v7skrk.png');
}

.soundify-pic {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('https://res.cloudinary.com/dgxpqnbwn/image/upload/v1695674455/Screenshot_2023-09-25_at_1.40.02_PM_mlz4pf.png')
;
}

.guitarbnb-pic
{
    background-repeat: no-repeat;
    height: 35vh;
    width: 100%;
}

.project-title {
    font-size: 25px;
    color: #0d0d0d;

    text-align: center;
    line-height: 2rem;
    letter-spacing: .1rem;
    font-weight: 400;
    margin: 10px;
}

.project p {
    font-family: 'Share Tech', monospace;
    /* color: #e6e9e9; */
}


.gh-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 40px;
    border-radius: 8px;
}

.gh-link-holder {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.gh-link-holder a {
    text-decoration: none;
    color: #edf2f4;
    background-color: #151c29;
}

#projects-intro {
    color: #0d0d0d;
    /* -webkit-background-clip: text; */
    /* background-clip: text; */
    /* -webkit-text-fill-color: transparent; */
    /* background-image: linear-gradient(to right, #9076fc, #6de1e7); */
}

@media only screen and (max-width: 850px) {

    .project {
        width: 80%;
    }
}
