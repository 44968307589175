.nav-container {
  position: sticky;
  display: flex;
  background-color: #edf2f4;
  height: 50px;
  top: 0;
  z-index: 1000;
  padding: 1rem 6rem;
  justify-content: space-between;
  border-bottom: 1px solid rgba(153, 150, 150, 0.493);
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, .25),
      0 10px 16px -18px rgba(0, 0, 0, .3);
}

.nav-container a {
  height: 100%;
  text-decoration: none;
}

.nav-links {
  column-gap: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nav-links a {
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 40px;
  font-family: 'Arial', monospace;
  border-bottom: 0;
  color: #0D0D0D !important;
  text-decoration: none !important;
}

.nav-links a:hover {
  transition: ease-in-out .2s;
}

.nav-links a::after {
  content: '';
  width: 0;
  height: 3px;
  background-image: linear-gradient(to right, #9076fc, #6de1e7);
  transition: width ease-in-out .2s;
  border-radius: 18px;
}

.nav-links a:hover::after {
  width: 100%;
}

.nav-logo {
  width: fit-content;
  font-size: 2rem;
  display: flex;
  align-items: center;
}