.attribution {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    color: rgba(122, 122, 122, 0.63) !important;
    text-decoration: none !important;
}





