/* CSS code */

.about-container {
    background-color: #151c29;
    display: flex;
    flex-direction: column;
    margin: 0 1.25rem 1.25rem 1.25rem;
    border-radius: 8px;
}

#about-before {
    display: block;
    width: 100%;
    height: 50px;
}

.about-me-intro {
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: #edf2f4;
    margin: 6rem 6rem 2rem 6rem;
}

.about-me {
    color: #edf2f4;
    width: 65%;
    font-size: 1.3rem;
    text-align: left;
    font-family: 'Arial', monospace;
    line-height: 2rem;
    letter-spacing: .1rem;
    font-weight: 400;
    margin: 0;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, #edf2f4, #edf2f4);

    visibility: visible;
    opacity: 0;
    transition: opacity 0.6s cubic-bezier(0.5, 0, 0, 1) 0s;
}

.about-me span {
    font-size: 1.3rem;
    font-family: 'Arial', monospace;
    line-height: 2rem;
    letter-spacing: .1rem;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, #9076fc, #6de1e7);
}

.about-section-container {
    display: flex;
    margin: 0 8rem 6rem 8rem;
    column-gap: 30px;
}

.about-me-intro span {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, #9076fc, #6de1e7);
    width: 50px;
    height: 1px;
    content: '';
    width: 0;
    height: 1px;
}

.about-me-intro::before {
    content: './';
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, #9076fc, #6de1e7);
}

.tech-stack-container {
    width: 35%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    column-gap: 20px;
    row-gap: 10px;
}

.tech-stack-container div {
    display: flex;
    font-size: 14px;
    height: fit-content;
    align-items: center;
    width: fit-content;
    color: #424343;
    padding: 8px 12px;
    border-radius: 8px;
    background-image: linear-gradient(to right, #dfdede, #eef0f0);
}

@media only screen and (max-width: 1000px) {
    .about-me {
        width: 80%;
    }
}

@media (max-width: 900px) {
    .about-section-container {
        margin: 0 0 6rem 0;
        flex-direction: column;
        align-items: center;
    }

    .tech-stack-container {
        margin-top: 20px;
        width: 80%;
    }
}
